import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '../../pages/ContextMenu';
import { createGame, editGame, getGame } from '../../stores/Game';
import { Media } from '../../models/Media';
import { UserContext } from '../../providers/UserProvider';
import ShareMedia from '../ShareMedia';
import Card from '../Card';
import QuizzImg from '../../assets/quizz.svg?react';
import DuoImg from '../../assets/duo.svg?react';
import PuzzleImg from '../../assets/puzzle.svg?react';
import ReordonImg from '../../assets/reordon.svg?react';
import CreateForm from './CreateForm';
import { createMedia } from '../../stores/Media';

interface CreateGameProps extends WithTranslation {
    onReturn?: () => void;
    onChangeTitle?: (title: string) => void;
    initialData?: any;
}

const GameType: { [key: string]: string } = {
    'SOUVEN\'IN': 'QUIZ',
    'PUZZL\'IN': 'PUZZLE',
    'MEMOR\'IN': 'MEMORIZ',
    'SUDOK\'IN': 'SUDOKU',
    'PONG\'IN': 'PONG',
    'ORDON\'IN': 'ORDER',
    'TOURBILLON': 'TOURBILLON',
    'MELI-MOTS': 'MELI-MOTS'
}

const CreateGame = ({ t, onReturn, onChangeTitle, initialData }: CreateGameProps) => {
    const userDataProp = useContext(UserContext);
    const { subscribe } = useNavigation();
    const [type, setType] = useState('');
    const [step, setStep] = useState('CREATE');
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (initialData?.exercise?.id) {
                const game = await getGame(userDataProp?.token as string, initialData?.exercise?.id);
                setData(game);
                setType(GameType[game.type]);
            }
        };
        fetchData();
    }, [initialData]);

    useEffect(() => {
        const handleReturn = () => {
            if (type) {
                setData(null);
                setType('');
                return;
            }
            if (onReturn) onReturn();
        };

        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, []);

    const card = (Img: React.FunctionComponent<React.SVGProps<SVGSVGElement>>, title: string, nbPhoto: number, action: () => void) => (
        <Card margin={false} action={action}>
            <div className={style.card}>
                <Img className={style.img} />
                <div className={style.text}>
                    <div className={style.cardTitle}>{t(title)}</div>
                    <div>{t('{{count}} photo requise', { count: nbPhoto })}</div>
                </div>
            </div>
        </Card>
    );

    const handleCreate = (data: any) => {
        setData(data);
        setStep('SHARE');
    };

    const handleShare = async (sharingLists: any) => {
        if (!userDataProp?.token) return;

        const uploaded = await Promise.all(
            data?.toUpload?.map((element: any) => {
                const media: any = {
                    title: element.fileName,
                    type: 'IMAGE',
                    url: element.url,
                };
                return createMedia(userDataProp.token as string, media);
            })
        );

        const game: any = {
            title: data?.game?.title,
            type: type,
            congratulationsMessage: data?.game?.congratulationsMessage,
            encouragementMessage: data?.game?.encouragementMessage,
            reward: data?.game?.video?.id,
            mediaIdList: [...uploaded, ...data?.photos.map((photo: any) => photo.id)],
            familyList: sharingLists.familyList,
            proList: sharingLists.proList.map((pro: any) => ({
                id: pro,
                canWrite: true,
            })),
        }

        if (initialData) {
            const initialGame = await getGame(userDataProp.token as string, initialData?.exercise?.id);
            await editGame(userDataProp.token as string, initialGame.id, { ...initialGame, ...game });
        } else {
            await createGame(userDataProp.token as string, game);
        }
        if (onReturn) onReturn();
    };

    if (step.startsWith('SHARE')) return <ShareMedia onReturn={() => setStep('CREATE')} onChangeTitle={onChangeTitle} onShare={(data) => handleShare(data)} />;

    if (step === 'CREATE') {
        if (['QUIZ', 'MEMORIZ', 'PUZZLE', 'ORDER'].includes(type)) return <CreateForm data={data} type={type} onChangeTitle={onChangeTitle} onReturn={() => setType('')} onCreate={handleCreate} />;

        return (
            <>
                {!type && <>
                    <div className={style.subTitle}>{t('Sélectionnez 1 modèle de jeu à personnaliser à partir de vos photos...')}</div>
                    <div className={style.cardContainer}>
                        {card(QuizzImg, 'Bon titre', 6, () => setType('QUIZ'))}
                        {card(DuoImg, 'Duo', 18, () => setType('MEMORIZ'))}
                        {card(PuzzleImg, 'En morceaux', 1, () => setType('PUZZLE'))}
                        {card(ReordonImg, `Un peu d'ordre`, 1, () => setType('ORDER'))}
                    </div>
                </>}
            </>
        );
    }

    return null;
};

export default withTranslation()(CreateGame);