import { withTranslation, WithTranslation } from 'react-i18next';
import style from './style.module.css';
import NewsCard from '../NewsCard';
import { Game } from '../../models/Game';
import { MediaItem } from '../../models/Media';

const type = {
    'SOUVEN\'IN': 'quizz',
    'PUZZL\'IN': 'puzzle',
    'MEMOR\'IN': 'memory',
    'SUDOK\'IN': 'sudoku',
    'PONG\'IN': 'pingpong',
    'ORDON\'IN': 'reordon',
    'TOURBILLON': 'tourbillon',
    'MELI-MOTS': 'melimots',
}

interface NewsGridProps extends WithTranslation {
    data: (Game | MediaItem)[];
    onDisplay: (id: string) => void;
}

const NewsGrid = ({ t, data, onDisplay }: NewsGridProps) => {
    return (
        <div className={style.root}>
            {data?.map((item) => (
                <NewsCard
                    key={item.id}
                    authorName={item.authorType === 'ADMIN' ? 'Albert' : item.authorName || 'Albert'}
                    authorPicture={item.authorPicture}
                    type={
                        ['VIDEO', 'YOUTUBE'].includes(item.type || '')
                            ? 'VIDEO'
                            : ['IMAGE'].includes(item.type || '') ? 'IMAGE' : 'GAME'
                    }
                    image={
                        ['IMAGE', 'VIDEO', 'YOUTUBE'].includes(item.type || '')
                            ? (item as MediaItem).thumbnail || (item as MediaItem).url
                            : (item as Game).media?.[0]?.url
                    }
                    onClick={() => {
                        if (['IMAGE', 'VIDEO', 'YOUTUBE'].includes(item.type || '')) {
                            onDisplay(item.id);
                        } else {
                            window.location.href = `/${type[item.type as keyof typeof type]}/${item.id}/lvl`;
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default withTranslation()(NewsGrid);