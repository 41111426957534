import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '../Button/index';
import Play from './assets/play.svg?react';
import Puzzle from './assets/puzzle.svg?react';
import Picture from './assets/picture.svg?react';
import Albert from './assets/albert.svg?react';
import style from './style.module.css';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';
import ProfilePicture from '../ProfilePicture';

interface NewsCardProps extends WithTranslation {
    image: string;
    type?: 'GAME' | 'IMAGE' | 'VIDEO';
    blur?: boolean;
    authorName: string;
    authorPicture?: string;
    authorType?: string;
    onClick: () => void;
};

const NewsCard = ({ t, type, blur = true, image, authorName, authorType, authorPicture, onClick }: NewsCardProps) => {
    const Icon = {
        GAME: Puzzle,
        IMAGE: Picture,
        VIDEO: Play
    }[type || 'GAME'];
    const gameType = {
        GAME: 'un jeu',
        IMAGE: 'une image',
        VIDEO: 'une vidéo'
    }[type || 'GAME'];

    const initials = authorName.charAt(0).toUpperCase() + authorName.charAt(1).toUpperCase();

    return (
        <div
            style={{ backgroundImage: `url(${image})` }}
            className={style.root}
        >
            {!blur && <Icon className={style.icon} />}
            {blur && <div className={style.blur} />}
            {blur && (!authorPicture || authorType === 'ADMIN') && <Albert className={style.profilePic} />}
            {blur && authorPicture && authorType !== 'ADMIN' &&
                <div className={style.profilePic}>
                    <ProfilePicture initials={initials} />
                </div>}
            {blur && <div
                className={style.title}
            >
                <span className={style.bold}>
                    {t('{{authorName}}', { authorName: authorName })}
                </span>
                {t(' vous a envoyé ')}
                <span className={style.bold}>
                    {t('{{gameType}}', { gameType: gameType })}
                </span>
            </div>}
            {!blur ? <Button
                action={onClick}
                className={style.button}
                label={t('(Re)Découvrir')}
                theme="SECONDARY"
                size="SMALL"
            /> : <Button
                action={onClick}
                className={[style.button, style.buttonPrimary].join(' ')}
                label={t('Découvrir')}
                theme="PRIMARY"
                size="SMALL"
            />}
        </div>
    )
};

export default withTranslation()(NewsCard);